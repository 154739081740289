import { graphql, useStaticQuery } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Faq from 'components/pages/Faq'
import useEventPageVisited from 'hooks/use-event-page-visited'

export default function FaqPage() {
  useEventPageVisited('FAQ')
  const data = useStaticQuery(graphql`
    query {
      categories: allMdx(filter: { frontmatter: { template: { eq: "faqCategory" } } }) {
        nodes {
          id
          frontmatter {
            requiresAuth
            title
          }
        }
      }
      questions: allMdx(
        filter: { frontmatter: { draft: { ne: true }, template: { eq: "faq" } } }
        sort: { fields: [frontmatter___title], order: ASC }
      ) {
        nodes {
          id
          body
          frontmatter {
            faqCategory
            requiresAuth
            title
          }
        }
      }
    }
  `)

  const { categories, questions } = data

  const questionsGroups = _.groupBy(questions.nodes, (item) => item.frontmatter.faqCategory[0])

  return <Faq categories={categories} data={questionsGroups} className="Faq" />
}
